.CIO  {
    align-self: flex-start;
  }
  
  /* Style for User2's messages */
  .OtherSender {
    align-self: flex-end;
  }
  
  .active {
    transition: transform 0.2s;
    transform: scale(1.2);
  }
  .Tactive {

   font-weight: 550;

    color: black; 
  }
  
  /* .active-user {
    background-color: #f0f0f0; 
    color: black; 
  } */
  

  #sender {
    font-weight: 500;
  }
  
  /* App.css */
  
  /* Animation for message entrance */
  .message-enter {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.3s ease, transform 0.3s ease;
  }
  
  .message-enter-active {
    opacity: 1;
    transform: translateY(0);
  }
  
  .message-exit {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 0.3s ease, transform 0.3s ease;
  }
  
  .message-exit-active {
    opacity: 0;
    transform: translateY(-20px);
  }
  
  .content {
    animation: scroll 10s linear infinite;
  }
  
  @keyframes scroll {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(-100%);
    }
  }
  

  .draggable-modal {
    cursor: grab;
  }
  
  .draggable-modal:active {
    cursor: grabbing;
  }

  

  #Tactive {
    visibility:visible;
  }
  
  .loading {
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 10px;
    visibility: hidden;
    @media (max-width: 1068px) {
      right: 4%;
    }
  
    .dot {
      background-color: black;
      width: 5px;
      height: 5px;
      border-radius: 50%;
      margin: 0 2px;
      animation: bounce 1s infinite;
      animation-timing-function: cubic-bezier(0.05, 0.27, 0.68, 1.34);
      @media (max-width : 600px) {
        width: 2px;
        height: 2px;
        margin-top: 15px;
       
    }
    }
  
    .dot:nth-child(2) {
      animation-delay: 0.1s;
    }
  
    .dot:nth-child(3) {
      animation-delay: 0.2s;
    }
  }
  
  @keyframes bounce {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-7px);
    }
  }
  
  @media (max-width : 600px) {
   
    @keyframes bounce {
      0%, 100% {
        transform: translateY(0);
      }
      50% {
        transform: translateY(-4px);
      }
    }
}
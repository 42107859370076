.KateSullivan  {
  align-self: flex-start;
}

/* Style for User2's messages */
.BenCarter {
  align-self: flex-end;
}

.active {
  transition: transform 0.2s;
  transform: scale(1.2);
}

.Tactive {

    color: black;
    font-weight: 500;

}



#sender {
  font-weight: 500;
}

/* App.css */

/* Animation for message entrance */
.message-enter {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.message-enter-active {
  opacity: 1;
  transform: translateY(0);
}

.message-exit {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.message-exit-active {
  opacity: 0;
  transform: translateY(-20px);
}

.content {
  animation: scroll 10s linear infinite;
}

@keyframes scroll {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-100%);
  }
}



.flex{
  display: grid;
  grid-template-columns: repeat(3,1fr);
  gap: 20px;
}

@media (max-width: 600px) {
  .flex{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap: 20px;
  }
}

.flex2{
  display: grid;
  grid-template-columns: repeat(2,1fr);
  gap: 25px;
}

[type="radio"]:focus {
  outline: none;
}


.popup-animation {
  opacity: 1;
  transition: opacity 0.3s ease-in;
}

.popup-animation-exit {
  opacity: 0;
  transition: opacity 0.3s ease-out;
}

.draggable-modal {
  cursor: grab;
}

.draggable-modal:active {
  cursor: grabbing;
}

#Tactive {
  visibility:visible;
}

.loading {
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 10px;
  visibility: hidden;
  @media (max-width: 1068px) {
    right: 4%;
  }

  .dot {
    background-color: black;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    margin: 0 2px;
    animation: bounce 1s infinite;
    animation-timing-function: cubic-bezier(0.05, 0.27, 0.68, 1.34);
    @media (max-width : 600px) {
        width: 2px;
        height: 2px;
        margin-top: 15px;
    }
  }

  .dot:nth-child(2) {
    animation-delay: 0.1s;
  }

  .dot:nth-child(3) {
    animation-delay: 0.2s;
  }
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-7px);
  }
}



#container {
  width: 55%;
  margin: auto;
  margin-top: 25px;
  padding: 50px 0px 80px 0px;
  font-family: Fredoka;
  font-size: 25px;
  font-weight: 500;
  /* background-color: #c7afaf; */
  
  background-image: linear-gradient(32deg, grey 0%, white 100%);
}

.containerChild {
  border: 1px solid #691212;
  width: 80%;
  margin: auto;
  padding: 5px;
  position: relative;
  transition: 0.4s ease-in;
  z-index: 1;
}

.containerChild ::before,
.containerChild ::after {
  position: absolute;
  content: "";
  z-index: 1;
}

.containerChild:hover {
  cursor: pointer;
  color: white;
  background-color: #17181c;
}

@media screen and (max-width: 600px) {
  #container {
    width: 90%;
    padding: 1px 0px 20px 0px;
    margin-top: 10px;
  }
  .containerChild{
    width: 90%;
    padding: 2px;
  }
}
